import React from 'react'
import Layout from '../components/layout/Layout'
import PageContainer from '../components/layout/PageContainer'
import Hero from '../components/hero/Hero'
import Heading from '../components/typograph/Heading'
import styled from 'styled-components'
import { DARK_GREEN, TEXT } from '../constants/Colors'

import contactImage from '../images/Banner-Contato-Desk.jpg'
import contactImageMobile from '../images/Banner-Contato-Mobile.jpg'
import LinkButton from '../components/buttons/LinkButton'

const Container = styled.div`
  max-width: 1000px;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  color: ${TEXT};
  line-height: 1.5;
`

const MatriculaEnviada = () => (
  <Layout title="Contato - HDScience">
    <Hero image={contactImage} mobileImage={contactImageMobile}>
      <Heading
        type="h1"
        style={{ color: DARK_GREEN, textAlign: 'center', paddingTop: 80 }}
      >
        CONTATO
      </Heading>
    </Hero>
    <PageContainer style={{ marginBottom: 40 }}>
      <Heading
        type="h2"
        style={{ marginBottom: 16, textAlign: 'center' }}
        color={DARK_GREEN}
      >
        Obrigado pelo seu contato!
      </Heading>
      <Container>
        Em breve responderemos sua mensagem.
        <br />
        <br />
        <br />
        <br />
        <LinkButton to="/">VOLTAR PARA PÁGINA PRINCIPAL</LinkButton>
      </Container>
    </PageContainer>
  </Layout>
)

export default MatriculaEnviada
